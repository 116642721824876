/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Languages } from "../models";
import { fetchByPath, validateField } from "./utils";
import { DataStore } from "aws-amplify";
export default function LanguagesCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    nickname: "",
    languages: "",
    stats: "",
    datavis: false,
    contributors: "",
  };
  const [nickname, setNickname] = React.useState(initialValues.nickname);
  const [languages, setLanguages] = React.useState(initialValues.languages);
  const [stats, setStats] = React.useState(initialValues.stats);
  const [datavis, setDatavis] = React.useState(initialValues.datavis);
  const [contributors, setContributors] = React.useState(
    initialValues.contributors
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setNickname(initialValues.nickname);
    setLanguages(initialValues.languages);
    setStats(initialValues.stats);
    setDatavis(initialValues.datavis);
    setContributors(initialValues.contributors);
    setErrors({});
  };
  const validations = {
    nickname: [{ type: "Required" }],
    languages: [{ type: "Required" }],
    stats: [{ type: "Required" }],
    datavis: [],
    contributors: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          nickname,
          languages,
          stats,
          datavis,
          contributors,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value.trim() === "") {
              modelFields[key] = undefined;
            }
          });
          await DataStore.save(new Languages(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "LanguagesCreateForm")}
      {...rest}
    >
      <TextField
        label="Nickname"
        isRequired={true}
        isReadOnly={false}
        value={nickname}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nickname: value,
              languages,
              stats,
              datavis,
              contributors,
            };
            const result = onChange(modelFields);
            value = result?.nickname ?? value;
          }
          if (errors.nickname?.hasError) {
            runValidationTasks("nickname", value);
          }
          setNickname(value);
        }}
        onBlur={() => runValidationTasks("nickname", nickname)}
        errorMessage={errors.nickname?.errorMessage}
        hasError={errors.nickname?.hasError}
        {...getOverrideProps(overrides, "nickname")}
      ></TextField>
      <TextField
        label="Languages"
        isRequired={true}
        isReadOnly={false}
        value={languages}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nickname,
              languages: value,
              stats,
              datavis,
              contributors,
            };
            const result = onChange(modelFields);
            value = result?.languages ?? value;
          }
          if (errors.languages?.hasError) {
            runValidationTasks("languages", value);
          }
          setLanguages(value);
        }}
        onBlur={() => runValidationTasks("languages", languages)}
        errorMessage={errors.languages?.errorMessage}
        hasError={errors.languages?.hasError}
        {...getOverrideProps(overrides, "languages")}
      ></TextField>
      <TextField
        label="Stats"
        isRequired={true}
        isReadOnly={false}
        value={stats}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nickname,
              languages,
              stats: value,
              datavis,
              contributors,
            };
            const result = onChange(modelFields);
            value = result?.stats ?? value;
          }
          if (errors.stats?.hasError) {
            runValidationTasks("stats", value);
          }
          setStats(value);
        }}
        onBlur={() => runValidationTasks("stats", stats)}
        errorMessage={errors.stats?.errorMessage}
        hasError={errors.stats?.hasError}
        {...getOverrideProps(overrides, "stats")}
      ></TextField>
      <SwitchField
        label="Datavis"
        defaultChecked={false}
        isDisabled={false}
        isChecked={datavis}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              nickname,
              languages,
              stats,
              datavis: value,
              contributors,
            };
            const result = onChange(modelFields);
            value = result?.datavis ?? value;
          }
          if (errors.datavis?.hasError) {
            runValidationTasks("datavis", value);
          }
          setDatavis(value);
        }}
        onBlur={() => runValidationTasks("datavis", datavis)}
        errorMessage={errors.datavis?.errorMessage}
        hasError={errors.datavis?.hasError}
        {...getOverrideProps(overrides, "datavis")}
      ></SwitchField>
      <TextField
        label="Contributors"
        isRequired={false}
        isReadOnly={false}
        value={contributors}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              nickname,
              languages,
              stats,
              datavis,
              contributors: value,
            };
            const result = onChange(modelFields);
            value = result?.contributors ?? value;
          }
          if (errors.contributors?.hasError) {
            runValidationTasks("contributors", value);
          }
          setContributors(value);
        }}
        onBlur={() => runValidationTasks("contributors", contributors)}
        errorMessage={errors.contributors?.errorMessage}
        hasError={errors.contributors?.hasError}
        {...getOverrideProps(overrides, "contributors")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
