// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Recruitments = {
  "ONE_TO_FIVE": "ONE_TO_FIVE",
  "FIVE_TO_THEN": "FIVE_TO_THEN",
  "MORE_THAN_TEN": "MORE_THAN_TEN",
  "UNKNOWN": "UNKNOWN"
};

const Confident = {
  "ONE": "ONE",
  "TWO": "TWO",
  "THREE": "THREE",
  "FOUR": "FOUR",
  "FIVE": "FIVE"
};

const { Languages, User } = initSchema(schema);

export {
  Languages,
  User,
  Recruitments,
  Confident
};