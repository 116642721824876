/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { User } from "../models";
import { fetchByPath, validateField } from "./utils";
import { DataStore } from "aws-amplify";
export default function UserUpdateForm(props) {
  const {
    id: idProp,
    user: userModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    email: "",
    nickname: "",
    name: "",
    use_ats: false,
    github_account_associated: false,
    objective_confidence: "",
    why_confidence: "",
    entreprise: "",
    country: "",
    recruitments_planned: "",
  };
  const [email, setEmail] = React.useState(initialValues.email);
  const [nickname, setNickname] = React.useState(initialValues.nickname);
  const [name, setName] = React.useState(initialValues.name);
  const [use_ats, setUse_ats] = React.useState(initialValues.use_ats);
  const [github_account_associated, setGithub_account_associated] =
    React.useState(initialValues.github_account_associated);
  const [objective_confidence, setObjective_confidence] = React.useState(
    initialValues.objective_confidence
  );
  const [why_confidence, setWhy_confidence] = React.useState(
    initialValues.why_confidence
  );
  const [entreprise, setEntreprise] = React.useState(initialValues.entreprise);
  const [country, setCountry] = React.useState(initialValues.country);
  const [recruitments_planned, setRecruitments_planned] = React.useState(
    initialValues.recruitments_planned
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = userRecord
      ? { ...initialValues, ...userRecord }
      : initialValues;
    setEmail(cleanValues.email);
    setNickname(cleanValues.nickname);
    setName(cleanValues.name);
    setUse_ats(cleanValues.use_ats);
    setGithub_account_associated(cleanValues.github_account_associated);
    setObjective_confidence(cleanValues.objective_confidence);
    setWhy_confidence(cleanValues.why_confidence);
    setEntreprise(cleanValues.entreprise);
    setCountry(cleanValues.country);
    setRecruitments_planned(cleanValues.recruitments_planned);
    setErrors({});
  };
  const [userRecord, setUserRecord] = React.useState(userModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(User, idProp)
        : userModelProp;
      setUserRecord(record);
    };
    queryData();
  }, [idProp, userModelProp]);
  React.useEffect(resetStateValues, [userRecord]);
  const validations = {
    email: [{ type: "Required" }, { type: "Email" }],
    nickname: [{ type: "Required" }],
    name: [{ type: "Required" }],
    use_ats: [{ type: "Required" }],
    github_account_associated: [{ type: "Required" }],
    objective_confidence: [{ type: "Required" }],
    why_confidence: [],
    entreprise: [{ type: "Required" }],
    country: [{ type: "Required" }],
    recruitments_planned: [{ type: "Required" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          email,
          nickname,
          name,
          use_ats,
          github_account_associated,
          objective_confidence,
          why_confidence,
          entreprise,
          country,
          recruitments_planned,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value.trim() === "") {
              modelFields[key] = undefined;
            }
          });
          await DataStore.save(
            User.copyOf(userRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "UserUpdateForm")}
      {...rest}
    >
      <TextField
        label="Email"
        isRequired={true}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email: value,
              nickname,
              name,
              use_ats,
              github_account_associated,
              objective_confidence,
              why_confidence,
              entreprise,
              country,
              recruitments_planned,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Nickname"
        isRequired={true}
        isReadOnly={false}
        value={nickname}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              nickname: value,
              name,
              use_ats,
              github_account_associated,
              objective_confidence,
              why_confidence,
              entreprise,
              country,
              recruitments_planned,
            };
            const result = onChange(modelFields);
            value = result?.nickname ?? value;
          }
          if (errors.nickname?.hasError) {
            runValidationTasks("nickname", value);
          }
          setNickname(value);
        }}
        onBlur={() => runValidationTasks("nickname", nickname)}
        errorMessage={errors.nickname?.errorMessage}
        hasError={errors.nickname?.hasError}
        {...getOverrideProps(overrides, "nickname")}
      ></TextField>
      <TextField
        label="Name"
        isRequired={true}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              nickname,
              name: value,
              use_ats,
              github_account_associated,
              objective_confidence,
              why_confidence,
              entreprise,
              country,
              recruitments_planned,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <SwitchField
        label="Use ats"
        defaultChecked={false}
        isDisabled={false}
        isChecked={use_ats}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              email,
              nickname,
              name,
              use_ats: value,
              github_account_associated,
              objective_confidence,
              why_confidence,
              entreprise,
              country,
              recruitments_planned,
            };
            const result = onChange(modelFields);
            value = result?.use_ats ?? value;
          }
          if (errors.use_ats?.hasError) {
            runValidationTasks("use_ats", value);
          }
          setUse_ats(value);
        }}
        onBlur={() => runValidationTasks("use_ats", use_ats)}
        errorMessage={errors.use_ats?.errorMessage}
        hasError={errors.use_ats?.hasError}
        {...getOverrideProps(overrides, "use_ats")}
      ></SwitchField>
      <SwitchField
        label="Github account associated"
        defaultChecked={false}
        isDisabled={false}
        isChecked={github_account_associated}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              email,
              nickname,
              name,
              use_ats,
              github_account_associated: value,
              objective_confidence,
              why_confidence,
              entreprise,
              country,
              recruitments_planned,
            };
            const result = onChange(modelFields);
            value = result?.github_account_associated ?? value;
          }
          if (errors.github_account_associated?.hasError) {
            runValidationTasks("github_account_associated", value);
          }
          setGithub_account_associated(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "github_account_associated",
            github_account_associated
          )
        }
        errorMessage={errors.github_account_associated?.errorMessage}
        hasError={errors.github_account_associated?.hasError}
        {...getOverrideProps(overrides, "github_account_associated")}
      ></SwitchField>
      <SelectField
        label="Objective confidence"
        placeholder="Please select an option"
        isDisabled={false}
        value={objective_confidence}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              nickname,
              name,
              use_ats,
              github_account_associated,
              objective_confidence: value,
              why_confidence,
              entreprise,
              country,
              recruitments_planned,
            };
            const result = onChange(modelFields);
            value = result?.objective_confidence ?? value;
          }
          if (errors.objective_confidence?.hasError) {
            runValidationTasks("objective_confidence", value);
          }
          setObjective_confidence(value);
        }}
        onBlur={() =>
          runValidationTasks("objective_confidence", objective_confidence)
        }
        errorMessage={errors.objective_confidence?.errorMessage}
        hasError={errors.objective_confidence?.hasError}
        {...getOverrideProps(overrides, "objective_confidence")}
      >
        <option
          children="One"
          value="ONE"
          {...getOverrideProps(overrides, "objective_confidenceoption0")}
        ></option>
        <option
          children="Two"
          value="TWO"
          {...getOverrideProps(overrides, "objective_confidenceoption1")}
        ></option>
        <option
          children="Three"
          value="THREE"
          {...getOverrideProps(overrides, "objective_confidenceoption2")}
        ></option>
        <option
          children="Four"
          value="FOUR"
          {...getOverrideProps(overrides, "objective_confidenceoption3")}
        ></option>
        <option
          children="Five"
          value="FIVE"
          {...getOverrideProps(overrides, "objective_confidenceoption4")}
        ></option>
      </SelectField>
      <TextField
        label="Why confidence"
        isRequired={false}
        isReadOnly={false}
        value={why_confidence}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              nickname,
              name,
              use_ats,
              github_account_associated,
              objective_confidence,
              why_confidence: value,
              entreprise,
              country,
              recruitments_planned,
            };
            const result = onChange(modelFields);
            value = result?.why_confidence ?? value;
          }
          if (errors.why_confidence?.hasError) {
            runValidationTasks("why_confidence", value);
          }
          setWhy_confidence(value);
        }}
        onBlur={() => runValidationTasks("why_confidence", why_confidence)}
        errorMessage={errors.why_confidence?.errorMessage}
        hasError={errors.why_confidence?.hasError}
        {...getOverrideProps(overrides, "why_confidence")}
      ></TextField>
      <TextField
        label="Entreprise"
        isRequired={true}
        isReadOnly={false}
        value={entreprise}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              nickname,
              name,
              use_ats,
              github_account_associated,
              objective_confidence,
              why_confidence,
              entreprise: value,
              country,
              recruitments_planned,
            };
            const result = onChange(modelFields);
            value = result?.entreprise ?? value;
          }
          if (errors.entreprise?.hasError) {
            runValidationTasks("entreprise", value);
          }
          setEntreprise(value);
        }}
        onBlur={() => runValidationTasks("entreprise", entreprise)}
        errorMessage={errors.entreprise?.errorMessage}
        hasError={errors.entreprise?.hasError}
        {...getOverrideProps(overrides, "entreprise")}
      ></TextField>
      <TextField
        label="Country"
        isRequired={true}
        isReadOnly={false}
        value={country}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              nickname,
              name,
              use_ats,
              github_account_associated,
              objective_confidence,
              why_confidence,
              entreprise,
              country: value,
              recruitments_planned,
            };
            const result = onChange(modelFields);
            value = result?.country ?? value;
          }
          if (errors.country?.hasError) {
            runValidationTasks("country", value);
          }
          setCountry(value);
        }}
        onBlur={() => runValidationTasks("country", country)}
        errorMessage={errors.country?.errorMessage}
        hasError={errors.country?.hasError}
        {...getOverrideProps(overrides, "country")}
      ></TextField>
      <SelectField
        label="Recruitments planned"
        placeholder="Please select an option"
        isDisabled={false}
        value={recruitments_planned}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              nickname,
              name,
              use_ats,
              github_account_associated,
              objective_confidence,
              why_confidence,
              entreprise,
              country,
              recruitments_planned: value,
            };
            const result = onChange(modelFields);
            value = result?.recruitments_planned ?? value;
          }
          if (errors.recruitments_planned?.hasError) {
            runValidationTasks("recruitments_planned", value);
          }
          setRecruitments_planned(value);
        }}
        onBlur={() =>
          runValidationTasks("recruitments_planned", recruitments_planned)
        }
        errorMessage={errors.recruitments_planned?.errorMessage}
        hasError={errors.recruitments_planned?.hasError}
        {...getOverrideProps(overrides, "recruitments_planned")}
      >
        <option
          children="One to five"
          value="ONE_TO_FIVE"
          {...getOverrideProps(overrides, "recruitments_plannedoption0")}
        ></option>
        <option
          children="Five to then"
          value="FIVE_TO_THEN"
          {...getOverrideProps(overrides, "recruitments_plannedoption1")}
        ></option>
        <option
          children="More than ten"
          value="MORE_THAN_TEN"
          {...getOverrideProps(overrides, "recruitments_plannedoption2")}
        ></option>
        <option
          children="Unknown"
          value="UNKNOWN"
          {...getOverrideProps(overrides, "recruitments_plannedoption3")}
        ></option>
      </SelectField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || userModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || userModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
