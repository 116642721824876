import { useUser } from '@auth0/nextjs-auth0/client';
import { UserCreateForm } from '../src/ui-components';
import { useState, useEffect } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router'; // Add this import
import { usePostHog } from 'posthog-js/react';

import { Amplify, API, graphqlOperation } from 'aws-amplify';
import awsconfig from '../src/aws-exports';
import "@aws-amplify/ui-react/styles.css";
import { listLanguages,listUsers } from '../src/graphql/queries';

Amplify.configure(awsconfig);

export default function IndexPage() {
  const { user, error, isLoading } = useUser();
  const posthog = usePostHog()
  const router = useRouter(); // Add this line to use the useRouter hook
  const tableStyle = {
    borderCollapse: 'separate',
    borderSpacing: `20px 0`,
  };

  const [formCompleted, setFormCompleted] = useState(false);
  const [installInProgress, setInstallInProgress] = useState(false);
  const [installationCompleted, setInstallationCompleted] = useState(false);
  const [datavisCompleted,setdatavisCompleted] = useState(false);
  const [datavisInProgress,setdatavisInProgress] = useState(false);
  const [tableData, setTableData] = useState([]);
  
  const pollLanguages = async () => {
    try {
      const filter = {
        nickname: {
          eq: user.nickname,
        },
      };
      const result = await API.graphql(graphqlOperation(listLanguages, { filter }));
      //console.log(result.data.listLanguages);
      console.log("je polle")
      const languages = result.data.listLanguages.items;
      if (languages.length === 0) {
        setTimeout(pollLanguages, 1000);
        return ;
      }
      setInstallInProgress(false);
      setInstallationCompleted(true);

      if (!languages[languages.length-1].datavis) {
        setTimeout(pollLanguages, 1000);
        return ;
      }
      setdatavisInProgress(false);
      setdatavisCompleted(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (installationCompleted && !datavisCompleted) {
      setdatavisInProgress(true);
    }
  }, [installationCompleted, datavisCompleted]);  


  useEffect(() => {
    if (user) {
      posthog.identify(user.name, {
        email: user.email,
        name: user.name,
        github_url: `https://github.com/${user.nickname}`,
        org_id: user.org_id
      })
      //posthog?.group('company', user.org_id)
    }
  }, [posthog, user])

  useEffect(() => {

    if (user && datavisInProgress || installInProgress) {
      pollLanguages();
    }

    // Check if the user exists and skip form completion if found
    const checkUserAndSkipFormCompletion = async () => {
      if (user) {
        console.log(user)
        const filter = {
          nickname: {
            eq: user.nickname,
          },
        };
        const result = await API.graphql(graphqlOperation(listUsers, { filter }));
        const users = result.data.listUsers.items;
        if (users.length > 0) {
          setFormCompleted(true);
          const languageResult = await API.graphql(graphqlOperation(listLanguages, { filter }));
          const languages = languageResult.data.listLanguages.items;
          console.log(languages[languages.length-1]);

          if (languages.length > 0) {
            setInstallationCompleted(true);
            
            if (languages[languages.length-1].datavis) {
              
              //console.log(languages[0].stats);
              const jsonData = JSON.parse(languages[languages.length-1].stats);
              const contribData = JSON.parse(languages[languages.length-1].contributors);
              const tempTableData = [];

              for (const repo in jsonData) {
                  tempTableData.push({
                    repo: repo,
                    contrib: contribData[repo],
                  });
              }
              setTableData(tempTableData);
              setdatavisCompleted(true);
              
            }
          }
          
        }
      }
    };
    checkUserAndSkipFormCompletion();

  }, [installInProgress, user, datavisInProgress]);

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <div>Loading ...</div>
    </div>;
  }

  if (error) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <div>{error.message}</div>
    </div>;
  }

  const getUrl = (row) => `https://the-dow-images.s3.amazonaws.com/${row}/languages.svg`;
  const libsUrl = (row) => `https://the-dow-images.s3.amazonaws.com/${row}/dependency.csv`;
  const repoUrl = (row) => `https://github.com/${row}`;
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      {user ? (
        <div>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Image src={user.picture} alt={user.name} width={100} height={100} />
          <h2> Welcome {user.name}</h2>
          <a href={!datavisCompleted ? 'https://www.loom.com/share/9dec239d55f4431bb9e7b49781e7900b?t=17' : 'https://www.loom.com/share/916dbc677be54640948258f9de841249'} target='_blank'>
            Need help? Click here to see instructions
          </a>

          {!formCompleted && (
            <UserCreateForm onSubmit={(fields) => {
              posthog?.capture('KYC Form submitted')
              const updatedFields = {}
              updatedFields["email"] = user.email
              updatedFields["name"] = user.name
              updatedFields["nickname"] = user.nickname
              Object.keys(fields).forEach(key => {
                if (typeof fields[key] === 'string') {
                  updatedFields[key] = fields[key].trim()
                } else {
                  updatedFields[key] = fields[key]
                }
              })
              setFormCompleted(true)
              return updatedFields
            }}
            onError={(error) => { console.log(error)}} />
          )}
          {formCompleted && !installationCompleted && (
            <>
            <button onClick={() => {
              window.open('https://github.com/apps/The-Dow/installations/new','_blank', 'height=500,width=800,left=200,top=200,screenX=200,screenY=200');
              setInstallInProgress(true);
            }}>
              Install the app
            </button>
            <p><b>We don't need to access to your source code just metadatda</b></p>
            <p>If you don't see your organization when clicking on Install the app,<a href='https://join.slack.com/t/thedowgroupe/shared_invite/zt-1t5sbsasl-u8qwgqExmWJKnENex_ck4Q'>send me slack message</a></p>
            {installInProgress && <div> Please wait while we are finishing to install app on your organization...</div>}
            <a href="/api/auth/logout">Logout</a>
            </>
          )}
          {installationCompleted && !datavisCompleted && (
            <div>
              <b>Installation completed.</b>
              <p>We are computing stats...</p>
              <a href="/api/auth/logout">Logout</a>
            </div>
          )}
          { datavisCompleted && (
            <div >
              <>
              <a href='https://join.slack.com/t/thedowgroupe/shared_invite/zt-1t5sbsasl-u8qwgqExmWJKnENex_ck4Q' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> Join slack group, I will be happy to help you get the most of these data</a>
              <a href="/api/auth/logout" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >Logout</a>
              </>
            <h3>Mean Language Percentages</h3>
            <table >
              <thead>
                <tr>
                  <th>Repository</th>
                  <th>Languages</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>All the repos (main languages used)</td>
                    <td><Image data-attr="autocapture-button" src={getUrl(user.nickname)} width={400} height={200} alt="a picture"/></td>
                  </tr>
              </tbody>
            </table>
            <h3>Language Percentages by Repository</h3>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th>Repository</th>
                  <th>Languages</th>
                  <th>Contributors</th>
                  <th>Libraries</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td>
                    <a href={repoUrl(row.repo)} target="_blank" rel="noopener noreferrer">
                            {row.repo}
                          </a>
                    </td>
                    <td><Image src={getUrl(user.nickname+"/"+row.repo)} width={400} height={200} alt="a picture"/></td>
                    <td style={{ maxWidth: '300px', wordWrap: 'break-word' }}>
                    {row.contrib
                    .map(
                      (user) => (
                        <span key={user.login}>
                          <a href={user.html_url} target="_blank" rel="noopener noreferrer">
                            {user.login}
                          </a>
                          ({user.contributions})
                        </span>
                        )
                      )
                      .reduce((prev, curr) => [prev, ', ', curr], [])}
                    </td>
                    <td>
                    <a href={libsUrl(user.nickname+"/"+row.repo)} target="_blank" rel="noopener noreferrer">
                            Download ( only python,javascript,java)
                          </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}
          </div>
        </div>
      ) : (
        window.location.href = "/api/auth/login"
      )}
    </div>
  );
}
