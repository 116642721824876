/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://aunuddae5bbb7poywalss4kuhy.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-uloo3iwzuzc4rdwqjv3ymvwkpy",
    "aws_cognito_identity_pool_id": "eu-central-1:c1250bae-422d-41d5-a9e2-7340ade2e56e",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_G9l0RNv6a",
    "aws_user_pools_web_client_id": "4das2j79q5a1qomm4ooq3j5ker",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
