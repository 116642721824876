/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  Heading,
  Radio,
  RadioGroupField,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { User } from "../models";
import { fetchByPath, validateField } from "./utils";
import { DataStore } from "aws-amplify";
export default function UserCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    use_ats: undefined,
    github_account_associated: undefined,
    recruitments_planned: "",
    objective_confidence: undefined,
    why_confidence: "",
    entreprise: "",
    country: "",
  };
  const [use_ats, setUse_ats] = React.useState(initialValues.use_ats);
  const [github_account_associated, setGithub_account_associated] =
    React.useState(initialValues.github_account_associated);
  const [recruitments_planned, setRecruitments_planned] = React.useState(
    initialValues.recruitments_planned
  );
  const [objective_confidence, setObjective_confidence] = React.useState(
    initialValues.objective_confidence
  );
  const [why_confidence, setWhy_confidence] = React.useState(
    initialValues.why_confidence
  );
  const [entreprise, setEntreprise] = React.useState(initialValues.entreprise);
  const [country, setCountry] = React.useState(initialValues.country);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setUse_ats(initialValues.use_ats);
    setGithub_account_associated(initialValues.github_account_associated);
    setRecruitments_planned(initialValues.recruitments_planned);
    setObjective_confidence(initialValues.objective_confidence);
    setWhy_confidence(initialValues.why_confidence);
    setEntreprise(initialValues.entreprise);
    setCountry(initialValues.country);
    setErrors({});
  };
  const validations = {
    use_ats: [{ type: "Required" }],
    github_account_associated: [{ type: "Required" }],
    recruitments_planned: [{ type: "Required" }],
    objective_confidence: [{ type: "Required" }],
    why_confidence: [],
    entreprise: [{ type: "Required" }],
    country: [{ type: "Required" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          use_ats,
          github_account_associated,
          recruitments_planned,
          objective_confidence,
          why_confidence,
          entreprise,
          country,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value.trim() === "") {
              modelFields[key] = undefined;
            }
          });
          await DataStore.save(new User(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "UserCreateForm")}
      {...rest}
    >
      <Heading
        children="Please fill the from to help us know you to best serve :)"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <RadioGroupField
        label="Do you use an ATS ?"
        name="use_ats"
        isReadOnly={false}
        isRequired={true}
        onChange={(e) => {
          let value = e.target.value === "true";
          if (onChange) {
            const modelFields = {
              use_ats: value,
              github_account_associated,
              recruitments_planned,
              objective_confidence,
              why_confidence,
              entreprise,
              country,
            };
            const result = onChange(modelFields);
            value = result?.use_ats ?? value;
          }
          if (errors.use_ats?.hasError) {
            runValidationTasks("use_ats", value);
          }
          setUse_ats(value);
        }}
        onBlur={() => runValidationTasks("use_ats", use_ats)}
        errorMessage={errors.use_ats?.errorMessage}
        hasError={errors.use_ats?.hasError}
        {...getOverrideProps(overrides, "use_ats")}
      >
        <Radio
          children="Yes"
          value="true"
          {...getOverrideProps(overrides, "use_atsRadio0")}
        ></Radio>
        <Radio
          children="No"
          value="false"
          {...getOverrideProps(overrides, "use_atsRadio1")}
        ></Radio>
      </RadioGroupField>
      <RadioGroupField
        label="Does your github account associated to your company github organization ?"
        name="github_account_associated"
        isReadOnly={false}
        isRequired={true}
        onChange={(e) => {
          let value = e.target.value === "true";
          if (onChange) {
            const modelFields = {
              use_ats,
              github_account_associated: value,
              recruitments_planned,
              objective_confidence,
              why_confidence,
              entreprise,
              country,
            };
            const result = onChange(modelFields);
            value = result?.github_account_associated ?? value;
          }
          if (errors.github_account_associated?.hasError) {
            runValidationTasks("github_account_associated", value);
          }
          setGithub_account_associated(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "github_account_associated",
            github_account_associated
          )
        }
        errorMessage={errors.github_account_associated?.errorMessage}
        hasError={errors.github_account_associated?.hasError}
        {...getOverrideProps(overrides, "github_account_associated")}
      >
        <Radio
          children="Yes"
          value="true"
          {...getOverrideProps(overrides, "github_account_associatedRadio0")}
        ></Radio>
        <Radio
          children="No"
          value="false"
          {...getOverrideProps(overrides, "github_account_associatedRadio1")}
        ></Radio>
      </RadioGroupField>
      <SelectField
        label="How many recruitments are you planning this year ?"
        placeholder="Please select an option"
        isDisabled={false}
        value={recruitments_planned}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              use_ats,
              github_account_associated,
              recruitments_planned: value,
              objective_confidence,
              why_confidence,
              entreprise,
              country,
            };
            const result = onChange(modelFields);
            value = result?.recruitments_planned ?? value;
          }
          if (errors.recruitments_planned?.hasError) {
            runValidationTasks("recruitments_planned", value);
          }
          setRecruitments_planned(value);
        }}
        onBlur={() =>
          runValidationTasks("recruitments_planned", recruitments_planned)
        }
        errorMessage={errors.recruitments_planned?.errorMessage}
        hasError={errors.recruitments_planned?.hasError}
        {...getOverrideProps(overrides, "recruitments_planned")}
      >
        <option
          children="1-5"
          value="ONE_TO_FIVE"
          {...getOverrideProps(overrides, "recruitments_plannedoption0")}
        ></option>
        <option
          children="5-10"
          value="FIVE_TO_THEN"
          {...getOverrideProps(overrides, "recruitments_plannedoption1")}
        ></option>
        <option
          children="10+"
          value="MORE_THAN_TEN"
          {...getOverrideProps(overrides, "recruitments_plannedoption2")}
        ></option>
        <option
          children="Unknown"
          value="UNKNOWN"
          {...getOverrideProps(overrides, "recruitments_plannedoption3")}
        ></option>
      </SelectField>
      <RadioGroupField
        label="From 1 to 5 , how will you judge your confidence about it ?"
        name="objective_confidence"
        isReadOnly={false}
        isRequired={true}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              use_ats,
              github_account_associated,
              recruitments_planned,
              objective_confidence: value,
              why_confidence,
              entreprise,
              country,
            };
            const result = onChange(modelFields);
            value = result?.objective_confidence ?? value;
          }
          if (errors.objective_confidence?.hasError) {
            runValidationTasks("objective_confidence", value);
          }
          setObjective_confidence(value);
        }}
        onBlur={() =>
          runValidationTasks("objective_confidence", objective_confidence)
        }
        errorMessage={errors.objective_confidence?.errorMessage}
        hasError={errors.objective_confidence?.hasError}
        {...getOverrideProps(overrides, "objective_confidence")}
      >
        <Radio
          children="1"
          value="ONE"
          {...getOverrideProps(overrides, "objective_confidenceRadio0")}
        ></Radio>
        <Radio
          children="2"
          value="TWO"
          {...getOverrideProps(overrides, "objective_confidenceRadio1")}
        ></Radio>
        <Radio
          children="3"
          value="THREE"
          {...getOverrideProps(overrides, "objective_confidenceRadio2")}
        ></Radio>
        <Radio
          children="4"
          value="FOUR"
          {...getOverrideProps(overrides, "objective_confidenceRadio3")}
        ></Radio>
        <Radio
          children="5"
          value="FIVE"
          {...getOverrideProps(overrides, "objective_confidenceRadio4")}
        ></Radio>
      </RadioGroupField>
      <TextField
        label="Why do you thinks so ?"
        isRequired={false}
        isReadOnly={false}
        value={why_confidence}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              use_ats,
              github_account_associated,
              recruitments_planned,
              objective_confidence,
              why_confidence: value,
              entreprise,
              country,
            };
            const result = onChange(modelFields);
            value = result?.why_confidence ?? value;
          }
          if (errors.why_confidence?.hasError) {
            runValidationTasks("why_confidence", value);
          }
          setWhy_confidence(value);
        }}
        onBlur={() => runValidationTasks("why_confidence", why_confidence)}
        errorMessage={errors.why_confidence?.errorMessage}
        hasError={errors.why_confidence?.hasError}
        {...getOverrideProps(overrides, "why_confidence")}
      ></TextField>
      <TextField
        label="Company Name"
        isRequired={true}
        isReadOnly={false}
        value={entreprise}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              use_ats,
              github_account_associated,
              recruitments_planned,
              objective_confidence,
              why_confidence,
              entreprise: value,
              country,
            };
            const result = onChange(modelFields);
            value = result?.entreprise ?? value;
          }
          if (errors.entreprise?.hasError) {
            runValidationTasks("entreprise", value);
          }
          setEntreprise(value);
        }}
        onBlur={() => runValidationTasks("entreprise", entreprise)}
        errorMessage={errors.entreprise?.errorMessage}
        hasError={errors.entreprise?.hasError}
        {...getOverrideProps(overrides, "entreprise")}
      ></TextField>
      <TextField
        label="Country of residence"
        isRequired={true}
        isReadOnly={false}
        value={country}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              use_ats,
              github_account_associated,
              recruitments_planned,
              objective_confidence,
              why_confidence,
              entreprise,
              country: value,
            };
            const result = onChange(modelFields);
            value = result?.country ?? value;
          }
          if (errors.country?.hasError) {
            runValidationTasks("country", value);
          }
          setCountry(value);
        }}
        onBlur={() => runValidationTasks("country", country)}
        errorMessage={errors.country?.errorMessage}
        hasError={errors.country?.hasError}
        {...getOverrideProps(overrides, "country")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
